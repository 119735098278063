
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CustomTable from "@/shared/components/CustomTable.vue";
import { formatLocaleDateTimeShort } from "@/shared/utils/date";
import { usePoolTabDetails } from "@/shared/composables/usePoolTabDetails";
import { decimalToPercentage } from "@/shared/utils/numbers";
import { openInNewTab } from "@/shared/utils/browser";
import { config } from "@/shared/utils/config";
import { useBreakpoints } from "@/shared/utils/breakpoints";

export default defineComponent({
  components: { CustomTable },
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { isMobileDown } = useBreakpoints();

    const {
      blocksDataList,
      blocksLoading,
      blocksFetchMore,
      blocksFetchMoreLoading,
    } = usePoolTabDetails(props.poolHash);

    const columns = computed(() => [
      {
        title: t("Time"),
        dataIndex: "timestamp",
        key: "timestamp",
        slots: { customRender: "timestamp" },
      },
      {
        title: t("Epoch"),
        dataIndex: "epoch.number",
        key: "epoch.number",
      },
      {
        title: t("Slot"),
        dataIndex: "slotNumber",
        key: "slotNumber",
      },
      ...(isMobileDown.value
        ? []
        : [
            {
              title: t("Block"),
              dataIndex: "blockNumber",
              key: "blockNumber",
            },
            {
              title: t("Hash"),
              dataIndex: "blockHash",
              key: "blockHash",
              slots: { customRender: "hash" },
            },
            {
              title: t("TXs"),
              dataIndex: "txPercentage",
              key: "txPercentage",
              slots: { customRender: "txPercentage" },
            },
            {
              title: t("Version"),
              dataIndex: "versionNumber",
              key: "versionNumber",
            },
            {
              title: t("OpCertC"),
              dataIndex: "certIndex",
              key: "certIndex",
            },
          ]),
    ]);

    const handleRedirectToBlock = async (block_hash) => {
      openInNewTab(`${config.adaplusBlocksUrl}/${block_hash}`);
    };

    return {
      t,
      blocksDataList,
      blocksLoading,
      blocksFetchMore,
      blocksFetchMoreLoading,
      handleRedirectToBlock,
      columns,
    };
  },
  methods: { formatLocaleDateTimeShort, decimalToPercentage },
});
