
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LayoutMainSub from "@/shared/components/Layouts/LayoutMainSub.vue";
import NewsList from "@/shared/components/List/NewsList.vue";

export default defineComponent({
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  components: { LayoutMainSub, NewsList },
});
