
import { defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DualAxesPlot, DualAxesPlotProps } from "ant-design-charts-vue";
import LikeOutlined from "@ant-design/icons-vue/LikeOutlined";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { PoolGraphDetails_poolGraphDetails_PoolGraphDetailsResults_results } from "@/api/pool/__generated__/PoolGraphDetails";
import { localeFormatterPrettyShort } from "@/shared/utils/adaFormatters";
import ASpin from "ant-design-vue/lib/spin";

export default defineComponent({
  components: {
    DualAxesPlot,
    LikeOutlined,
    AppIcon,
    ASpin,
  },
  props: {
    loading: Boolean,
    data: {
      type: Array as PropType<
        PoolGraphDetails_poolGraphDetails_PoolGraphDetailsResults_results[]
      >,
      default: () => [],
    },
    height: {
      type: Number,
      default: 136,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const refDom = ref();
    const currentEpoch =
      ref<PoolGraphDetails_poolGraphDetails_PoolGraphDetailsResults_results>();

    const onChartRef = (ref) => {
      refDom.value = ref;

      ref.on("element:mouseover", (e) => {
        // Assign current on hover element data to currentEpoch
        currentEpoch.value = e?.data?.data;
      });
    };

    watch(
      () => props.data,
      (newData) => {
        if (refDom.value) {
          // update the current data on the graph
          refDom.value.options.data = [newData, newData];

          // trigger re-render on the graph
          refDom.value.update();
        }
      }
    );

    const config: DualAxesPlotProps = {
      theme: "default",
      data: [props.data, props.data],
      height: props.height,
      autoFit: true,
      smooth: true,
      legend: true,
      // Name of x-axis data to be fetched
      xField: "epochNumber",
      // configure x-axis
      xAxis: {
        tickInterval: 5, // Interval of Label on x-axis
        line: null, //remove bottom line of graph
        label: {
          style: {
            fill: "black", //color of x-axis labels
          },
        },
      },
      // Name of y-axis data to be fetched
      yField: ["voteCount", "voteStrike"],
      // since DualAxes are biaxial then yAxis type is an object type,
      yAxis: {
        // First yField, in this case: voteCount
        voteCount: {
          label: null, //remove data label for voteCount (horizontal label)
          grid: null, //remove horizontal line for voteCount
        },
        // Second yField, in this case: voteStrike
        voteStrike: {
          label: null, //remove data label for voteStrike (horizontal label)
          grid: null, //remove horizontal line for voteStrike
        },
      },
      geometryOptions: [
        {
          geometry: "column",
          color: "#D5C7FF",
        },
        {
          geometry: "line",
          color: "#3E5AFD",
          lineStyle: {
            lineWidth: 2,
          },
        },
      ],
      meta: {
        voteCount: {
          alias: t("Vote Count"),
        },
        voteStrike: {
          alias: t("Vote Strike"),
        },
      },
    };

    return { t, config, onChartRef, currentEpoch };
  },
  methods: { localeFormatterPrettyShort },
});
