import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { createPoolVoteGql } from "@/api/pool/createPoolVote";
import { poolDetailsGql } from "@/api/pool/poolDetails";
import { unvoteGql } from "@/api/pool/unvote";
import {
  CreatePoolVote,
  CreatePoolVoteVariables,
  CreatePoolVote_createPoolVote_PoolVote,
} from "@/api/pool/__generated__/CreatePoolVote";
import {
  PoolDetails,
  PoolDetailsVariables,
} from "@/api/pool/__generated__/PoolDetails";
import {
  Unvote,
  UnvoteVariables,
  Unvote_unvote_PoolVote,
} from "@/api/pool/__generated__/Unvote";
import { useQuery } from "@vue/apollo-composable";
import { isEmpty } from "lodash";
import { ref } from "vue";
import { CreatePoolVoteInput, UnvoteInput } from "__generated__/globalTypes";
import { sleep } from "../utils/browser";
import { apiErrorCodes } from "../utils/constants";
import { parseGqlResponse } from "../utils/graphql/responseParser";

export const usePoolVote = () => {
  const poolDetailsQueryEnabled = ref(false);
  const poolDetailsPoolHash = ref("");

  const { refetch } = useQuery<PoolDetails, PoolDetailsVariables>(
    poolDetailsGql,
    () => ({ input: { poolHash: poolDetailsPoolHash.value } }),
    () => ({ enabled: poolDetailsQueryEnabled.value })
  );

  const refetchPoolDetail = async (poolHash = "") => {
    if (!poolHash) {
      return;
    }

    poolDetailsQueryEnabled.value = true;
    poolDetailsPoolHash.value = poolHash;

    // add sleep to make sure ref have been updated before calling refetch
    // an ms late will cause pool details to not refetch
    await sleep(250);

    await refetch();
  };

  const { mutate: createPoolVoteMutate, loading: createPoolVoteLoading } =
    useCustomMutation<CreatePoolVote, CreatePoolVoteVariables>(
      createPoolVoteGql
    );

  const createPoolVote = async (input: CreatePoolVoteInput) => {
    const createPoolVoteResponse = await createPoolVoteMutate({
      input,
    });
    const parsedResponse =
      parseGqlResponse<CreatePoolVote_createPoolVote_PoolVote>(
        "PoolVote",
        createPoolVoteResponse,
        apiErrorCodes.AUTHENTICATION_ERROR
      );

    console.log("createPoolVote:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !createPoolVoteResponse) {
      throw new Error("Failed to CreatePoolVote");
    }

    await refetchPoolDetail(input.poolHash);

    return parsedResponse.data;
  };

  const { mutate: unvoteMutate, loading: unvotePoolLoading } =
    useCustomMutation<Unvote, UnvoteVariables>(unvoteGql);

  const unvotePool = async (input: UnvoteInput, poolHash: string) => {
    const unvoteResponse = await unvoteMutate({
      input,
    });
    const parsedResponse = parseGqlResponse<Unvote_unvote_PoolVote>(
      "PoolVote",
      unvoteResponse
    );

    console.log("unvote:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !unvoteResponse) {
      throw new Error("Failed to Unvote");
    }

    await refetchPoolDetail(poolHash);

    return parsedResponse.data;
  };

  return {
    createPoolVote,
    createPoolVoteLoading,
    unvotePool,
    unvotePoolLoading,
  };
};
