
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { i18nTranslate } from "@/plugins/i18n";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import map from "lodash/map";
import random from "lodash/random";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";
import GraphAxes from "@/shared/components/GraphAxes.vue";
import { Pools_pools_PoolItemResults_results } from "@/api/pool/__generated__/Pools";
import { usePoolGraphDetails } from "@/shared/composables/usePoolGraphDetails";
import { isNil } from "lodash";

const poolDetailsToShow = [
  {
    title: i18nTranslate("Last Rewards"),
    key: "mock_last_rewards",
  },
  {
    title: i18nTranslate("ROAM"),
    key: "mock_roam",
  },
  {
    title: i18nTranslate("ROA Lifetime"),
    key: "roaPercentageLifetime",
    formatter: (value) => `${value}%`,
  },
  {
    title: i18nTranslate("Live stake"),
    key: "poolEpochDetail.activeStakeAmount",
    formatter: adaCurrencyFormatter,
  },
  {
    title: i18nTranslate("Luck"),
    key: "luckPercentage",
    formatter: (value) => `${value}%`,
  },
  {
    title: i18nTranslate("Delegators"),
    key: "poolEpochDetail.delegatorsCount",
  },
  {
    title: i18nTranslate("Owner"),
    key: "mock_owner",
  },
  {
    title: i18nTranslate("Pledge"),
    key: "pledgeAmount",
    formatter: adaCurrencyFormatter,
  },
];

export default defineComponent({
  components: {
    GraphAxes,
  },
  props: {
    poolData: {
      type: Object as PropType<Pools_pools_PoolItemResults_results>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { poolGraph, poolGraphLoading } = usePoolGraphDetails(
      String(props?.poolData?.hash)
    );

    /**
     * Mock values
     */
    const mockDetailsToShow = {
      mock_last_rewards: "E346",
      mock_roam: `${random(1, 100, true).toFixed(2)}%`,
      mock_owner: "1 view",
    };

    const summaryList = computed(
      () =>
        map(poolDetailsToShow, ({ key, title, formatter }) => {
          let value = get(props.poolData, key) || mockDetailsToShow[key];
          if (formatter && isFunction(formatter)) {
            value = formatter(value);
          }
          return {
            key,
            title,
            value,
          };
        }).filter((item) => !isNil(item?.value)) // filter out items with undefined or null values
    );

    return { t, summaryList, poolGraph, poolGraphLoading };
  },
});
