
import { defineComponent, PropType } from "vue";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";
import { openInNewTab } from "@/shared/utils/browser";

export type PoolHandleType = {
  name?: string;
  value?: string;
  icon?: string;
};

export default defineComponent({
  props: {
    handles: Array as PropType<PoolHandleType[]>,
  },
  setup() {
    return {};
  },
  methods: { openInNewTab },
  components: { PlatformIcon },
});
