import { computed } from "vue";
import { useStore } from "vuex";

/**
 * Reusable composable for Profile updates
 *
 * @param
 * @returns
 */
export function useAccount() {
  const store = useStore();
  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
  const selectedUserId = computed(() => store.state.auth.userId);
  const isDelegated = computed(() => store.state.userInfo.isDelegated);

  return {
    isAuthenticated,
    selectedUserId,
    isDelegated,
  };
}
