
import { computed, defineComponent, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import PoolHeaderSummary from "@/web/views/Pool/PoolHeaderSummary.vue";
import PoolTableSummary from "@/web/views/Pool/PoolTableSummary.vue";
import PoolTabSummary from "@/web/views/Pool/PoolTabSummary.vue";
import LayoutMain from "@/shared/components/Layouts/LayoutMain.vue";
import { usePoolDetails } from "@/shared/composables/usePoolDetails";

export default defineComponent({
  components: {
    PoolHeaderSummary,
    PoolTableSummary,
    PoolTabSummary,
    LayoutMain,
  },
  props: {},
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const poolHash = computed(
      () => router?.currentRoute?.value?.params?.pool_hash as string
    );
    const { poolData, poolDataLoading } = usePoolDetails(poolHash.value);

    watchEffect(() => {
      console.log(poolData.value);
    });

    const handleBack = () => {
      /**
       * for now just use the browser's default back navigation
       *
       * @FIXME update to actual route for the back button
       */
      router.back();
    };

    return {
      t,
      poolData,
      poolDataLoading,
      handleBack,
      poolHash,
    };
  },
});
