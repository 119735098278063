
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { getPlatformIcon } from "@/shared/utils/platformIcons";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { getPlatformIcon },
  components: {},
});
