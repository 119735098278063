import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createBlock(_component_CustomTable, {
    columns: _ctx.columns,
    "data-source": _ctx.delegatorsDataList,
    loading: _ctx.delegatorsLoading,
    "show-pagination": false,
    "load-more-button": "",
    "load-more-loading": _ctx.delegatorsFetchMoreLoading,
    "page-size": _ctx.delegatorsDataList?.length,
    onLoadMore: _ctx.delegatorsFetchMore
  }, {
    timestamp: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.formatLocaleDateTimeShort(text)), 1)
    ]),
    address: _withCtx(({ record }) => [
      _createVNode(_component_a_button, {
        type: "link",
        size: "small",
        onClick: ($event: any) => (_ctx.handleRedirectToDeligation(record.address))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(record.fromStakingKey), 1)
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_text, {
          type: "secondary",
          class: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(record.addressSuffix), 1)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    amount: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.adaCurrencyFormatter(text)), 1)
    ]),
    _: 1
  }, 8, ["columns", "data-source", "loading", "load-more-loading", "page-size", "onLoadMore"]))
}