import gql from "graphql-tag";

export const poolGraphDetailsGql = gql`
  query PoolGraphDetails($input: PoolGraphDetailsInput!) {
    poolGraphDetails(input: $input) {
      ... on PoolGraphDetailsResults {
        results {
          voteCount
          voteStrike
          epoch {
            id
            created
            number
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
