import gql from "graphql-tag";

export const poolRewardsGql = gql`
  query PoolRewards($input: PoolRewardsInput!) {
    poolRewards(input: $input) {
      ... on PoolRewardsResults {
        results {
          epoch {
            id
            created
            number
          }
          blockCount
          activeStakeAmount
          delegatorsCount
          poolRewardsAmount
          delegatorRewardsAmount
          roaPercentage
          luckPercentage
          timestamp
        }
        pageInfo {
          nextOffset
        }
        totalCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
