
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
// import CheckOutlined from "@ant-design/icons-vue/CheckOutlined";
import LayoutMainSub from "@/shared/components/Layouts/LayoutMainSub.vue";
import CustomTable from "@/shared/components/CustomTable.vue";
import { usePoolTabDetails } from "@/shared/composables/usePoolTabDetails";
import { formatLocaleDateTimeShort } from "@/shared/utils/date";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";
import CustomCard from "@/shared/components/CustomCard.vue";

export default defineComponent({
  components: {
    LayoutMainSub,
    CustomTable,
    CustomCard,
    // CheckOutlined,
  },
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const {
      relaysDataList,
      relaysLoading,
      relaysFetchMore,
      relaysFetchMoreLoading,
      historiesDataList,
      historiesLoading,
      historiesFetchMore,
      historiesFetchMoreLoading,
    } = usePoolTabDetails(props.poolHash);

    const mockPoolAbout = {
      stake: "504.188k ₳",
      stakeKeyHash:
        "stake1uyltajtqjrsp2zfxkanh999m08w2r57uncskv4yzrjehgucdtyg9c",
      operatedBy:
        "Cardanians s.r.o.\nŠlapanická 1352/14, Brno, Czech Republic\nID: 29269580\nVAT ID: CZ29269580",
    };

    const replayColumns = [
      // Hide for now, see #3kqn26u
      // {
      //   title: t("Status"),
      //   dataIndex: "status",
      //   key: "status",
      //   slots: { customRender: "status" },
      // },
      {
        title: t("Location"),
        dataIndex: "location",
        key: "location",
      },
      {
        title: t("DNS name"),
        dataIndex: "dnsName",
        key: "dnsName",
      },
      {
        title: t("Port"),
        dataIndex: "port",
        key: "port",
      },
      {
        title: t("IP4"),
        dataIndex: "ip4Address",
        key: "ip4Address",
      },
      {
        title: t("IP6"),
        dataIndex: "ip6Address",
        key: "ip6Address",
      },
    ];

    // if card mode or mobile, filter columns to be used
    const replayColumnsCardMode = replayColumns.filter((item) =>
      ["port", "ip4Address", "ip6Address"].includes(item.dataIndex)
    );

    const historyColumns = [
      {
        title: t("Time"),
        dataIndex: "updateTimestamp",
        key: "updateTimestamp",
        slots: { customRender: "updateTimestamp" },
      },
      {
        title: t("Rewards"),
        dataIndex: "rewardAddress",
        key: "rewardAddress",
      },
      {
        title: t("Pledge"),
        dataIndex: "pledge",
        key: "pledge",
        slots: { customRender: "pledge" },
      },
      {
        title: t("Fixed"),
        dataIndex: "fixedCost",
        key: "fixedCost",
        slots: { customRender: "fixedCost" },
      },
      {
        title: t("Margin"),
        dataIndex: "margin",
        key: "margin",
        slots: { customRender: "margin" },
      },
    ];

    return {
      t,
      relaysDataList,
      relaysLoading,
      relaysFetchMore,
      relaysFetchMoreLoading,
      historiesDataList,
      historiesLoading,
      historiesFetchMore,
      historiesFetchMoreLoading,
      mockPoolAbout,
      replayColumns,
      replayColumnsCardMode,
      historyColumns,
    };
  },
  methods: { adaCurrencyFormatter, formatLocaleDateTimeShort },
});
