import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createBlock(_component_CustomTable, {
    columns: _ctx.columns,
    "data-source": _ctx.rewardsDataList,
    loading: _ctx.rewardsLoading,
    "show-pagination": false,
    "row-alignment": _ctx.TableRowAlignment.MIDDLE,
    "row-classes": [],
    "load-more-button": "",
    "load-more-loading": _ctx.rewardsFetchMoreLoading,
    "page-size": _ctx.rewardsDataList?.length,
    onLoadMore: _ctx.rewardsFetchMore
  }, {
    timestamp: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.formatLocaleDateTimeShort(text)), 1)
    ]),
    epoch: _withCtx(({ record }) => [
      _createVNode(_component_a_typography_text, {
        mark: record.isCurrent
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(record.epoch?.number), 1)
        ]),
        _: 2
      }, 1032, ["mark"])
    ]),
    blocks: _withCtx(({ record }) => [
      _createTextVNode(_toDisplayString(record.blockCount) + " / " + _toDisplayString(record.block_estimated), 1)
    ]),
    luckPercentage: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(text) + "%", 1)
    ]),
    activeStakeAmount: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.adaCurrencyFormatter(text)), 1)
    ]),
    rewards: _withCtx(({ record }) => [
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_ctx.t("pool {pool_reward}", {
            pool_reward: _ctx.adaCurrencyFormatter(record.poolRewardsAmount),
          })) + " ", 1),
        _createVNode(_component_a_typography_paragraph, { class: "small m-0" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("delegators {delegators_reward}", {
              delegators_reward: _ctx.adaCurrencyFormatter(
                record.delegatorRewardsAmount
              ),
            })), 1)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 1
  }, 8, ["columns", "data-source", "loading", "row-alignment", "load-more-loading", "page-size", "onLoadMore"]))
}