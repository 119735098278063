import gql from "graphql-tag";

export const poolRelaysGql = gql`
  query PoolRelays($input: PoolRelaysInput!) {
    poolRelays(input: $input) {
      ... on PoolRelaysResults {
        results {
          status
          location
          dnsName
          port
          ip4Address
          ip6Address
        }
        pageInfo {
          nextOffset
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
