import discord from "@/assets/vendor/discord.svg";
import telegram from "@/assets/vendor/telegram.svg";
import twitter from "@/assets/vendor/twitter.svg";

export const platformIcons = {
  discord,
  telegram,
  twitter,
};

export const getPlatformIcon = (platform: string) => {
  return platformIcons[platform];
};
