import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "graph-axes" }
const _hoisted_2 = { class: "mb-6 pt-8 graph-axes__current-epoch flex" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_LikeOutlined = _resolveComponent("LikeOutlined")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_DualAxesPlot = _resolveComponent("DualAxesPlot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_text, { class: "mr-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Current Epoch:")), 1)
        ]),
        _: 1
      }),
      (_ctx.currentEpoch)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.currentEpoch.voteCount)
              ? (_openBlock(), _createBlock(_component_a_typography_text, {
                  key: 0,
                  class: "mr-2 font-bold"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LikeOutlined, { class: "graph-axes__vote-icon mr-0.5" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("{count} votes", { count: _ctx.currentEpoch.voteCount })), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.currentEpoch.voteStrike)
              ? (_openBlock(), _createBlock(_component_a_typography_text, {
                  key: 1,
                  class: "mr-2 font-bold"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppIcon, {
                      name: "voteStake",
                      class: "graph-axes__vote-stake-icon"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("{count} vote strike", {
              count: _ctx.localeFormatterPrettyShort(_ctx.currentEpoch.voteStrike),
            })), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.loading || _ctx.data.length < 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "graph-axes__graph-loading-or-empty",
          style: _normalizeStyle({ height: `${_ctx.height}px`, 'margin-bottom': `-${_ctx.height}px` })
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_a_spin, { key: 0 }))
            : (_openBlock(), _createBlock(_component_a_typography_text, {
                key: 1,
                class: "small lighter text-center"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("No data available")), 1)
                ]),
                _: 1
              }))
        ], 4))
      : _createCommentVNode("", true),
    _createVNode(_component_DualAxesPlot, {
      config: _ctx.config,
      chartRef: _ctx.onChartRef
    }, null, 8, ["config", "chartRef"])
  ]))
}