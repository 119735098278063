
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CustomTable, {
  TableRowAlignment,
} from "@/shared/components/CustomTable.vue";
import { formatLocaleDateTimeShort } from "@/shared/utils/date";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";
import { usePoolTabDetails } from "@/shared/composables/usePoolTabDetails";
import { useBreakpoints } from "@/shared/utils/breakpoints";

export default defineComponent({
  components: { CustomTable },
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { isMobileDown } = useBreakpoints();

    const {
      rewardsDataList,
      rewardsLoading,
      rewardsFetchMore,
      rewardsFetchMoreLoading,
    } = usePoolTabDetails(props.poolHash);

    const columns = computed(() => [
      {
        title: t("Time"),
        dataIndex: "timestamp",
        key: "timestamp",
        slots: { customRender: "timestamp" },
      },
      {
        title: t("Epoch"),
        dataIndex: "epoch",
        key: "epoch",
        slots: { customRender: "epoch" },
      },
      {
        title: t("ROA"),
        dataIndex: "roaPercentage",
        key: "roaPercentage",
      },
      ...(isMobileDown.value
        ? []
        : [
            {
              title: t("Blocks"),
              dataIndex: "blocks",
              key: "blocks",
              slots: { customRender: "blocks" },
            },
            {
              title: t("Luck"),
              dataIndex: "luckPercentage",
              key: "luckPercentage",
              slots: { customRender: "luckPercentage" },
            },
            {
              title: t("Live stake"),
              dataIndex: "liveStake",
              key: "liveStake",
            },
            {
              title: t("Active stake"),
              dataIndex: "activeStakeAmount",
              key: "activeStakeAmount",
              slots: { customRender: "activeStakeAmount" },
            },
            {
              title: t("Delegators"),
              dataIndex: "delegatorsCount",
              key: "delegatorsCount",
            },
            {
              title: t("Rewards"),
              dataIndex: "rewards",
              key: "rewards",
              slots: { customRender: "rewards" },
            },
          ]),
    ]);

    return {
      t,
      rewardsDataList,
      rewardsLoading,
      rewardsFetchMore,
      rewardsFetchMoreLoading,
      TableRowAlignment,
      columns,
    };
  },
  methods: { adaCurrencyFormatter, formatLocaleDateTimeShort },
});
