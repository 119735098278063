import { computed, ref } from "vue";
import { useQuerySingleNode } from "@/api/graphqlClient/useQuerySingleNode";
import { poolBlocksGql } from "@/api/pool/poolBlocks";
import { poolDelegatorsGql } from "@/api/pool/poolDelegators";
import { poolRewardsGql } from "@/api/pool/poolRewards";
import {
  PoolBlocks,
  PoolBlocksVariables,
  PoolBlocks_poolBlocks_PoolBlocksResults,
} from "@/api/pool/__generated__/PoolBlocks";
import {
  PoolDelegators,
  PoolDelegatorsVariables,
  PoolDelegators_poolDelegators_PoolDelegatorsResults,
} from "@/api/pool/__generated__/PoolDelegators";
import {
  PoolRewards,
  PoolRewardsVariables,
  PoolRewards_poolRewards_PoolRewardsResults,
} from "@/api/pool/__generated__/PoolRewards";
import {
  parsePoolDelegation,
  parsePoolHistories,
  parsePoolRelays,
  parsePoolReward,
} from "@/shared/utils/pool";
import { poolRelaysGql } from "@/api/pool/poolRelays";
import {
  PoolRelays_poolRelays_PoolRelaysResults,
  PoolRelays,
  PoolRelaysVariables,
} from "@/api/pool/__generated__/PoolRelays";
import { poolHistoriesGql } from "@/api/pool/poolHistories";
import {
  PoolHistories_poolHistories_PoolHistoriesResults,
  PoolHistories,
  PoolHistoriesVariables,
} from "@/api/pool/__generated__/PoolHistories";

export const usePoolTabDetails = (poolHash: string) => {
  /**
   * BLOCKS
   */
  const blocksCanFetchMore = ref(true);
  const {
    data: blocksData,
    loading: blocksLoading,
    fetchMoreSimplePaginationInput: blocksFetchMoreSimplePaginationInput,
    fetchMoreLoading: blocksFetchMoreLoading,
  } = useQuerySingleNode<
    PoolBlocks_poolBlocks_PoolBlocksResults,
    PoolBlocks,
    PoolBlocksVariables
  >(
    poolBlocksGql,
    "PoolBlocksResults",
    () => ({
      input: {
        poolHash,
        pagination: {
          offset: 0,
          pageSize: 20,
        },
      },
    }),
    () => ({ enabled: !!poolHash })
  );

  const blocksFetchMore = async () => {
    await blocksFetchMoreSimplePaginationInput("poolBlocks");
  };
  // END BLOCKS

  /**
   * REWARDS
   */
  const rewardsCanFetchMore = ref(true);
  const {
    data: rewardsData,
    loading: rewardsLoading,
    fetchMoreSimplePaginationInput: rewardsFetchMoreSimplePaginationInput,
    fetchMoreLoading: rewardsFetchMoreLoading,
  } = useQuerySingleNode<
    PoolRewards_poolRewards_PoolRewardsResults,
    PoolRewards,
    PoolRewardsVariables
  >(
    poolRewardsGql,
    "PoolRewardsResults",
    () => ({
      input: {
        poolHash,
        pagination: {
          offset: 0,
          pageSize: 10,
        },
      },
    }),
    () => ({ enabled: !!poolHash })
  );

  const rewardsFetchMore = async () => {
    await rewardsFetchMoreSimplePaginationInput("poolRewards");
  };
  // END REWARDS

  /**
   * DELEGATORS
   */
  const delegatorsCanFetchMore = ref(true);
  const {
    data: delegatorsData,
    loading: delegatorsLoading,
    fetchMoreSimplePaginationInput: delegatorsFetchMoreSimplePaginationInput,
    fetchMoreLoading: delegatorsFetchMoreLoading,
  } = useQuerySingleNode<
    PoolDelegators_poolDelegators_PoolDelegatorsResults,
    PoolDelegators,
    PoolDelegatorsVariables
  >(
    poolDelegatorsGql,
    "PoolDelegatorsResults",
    () => ({
      input: {
        poolHash,
        pagination: {
          offset: 0,
          pageSize: 10,
        },
      },
    }),
    () => ({ enabled: !!poolHash })
  );

  const delegatorsFetchMore = async () => {
    await delegatorsFetchMoreSimplePaginationInput("poolDelegators");
  };
  // END DELEGATORS

  /**
   * RELAYS
   */
  const relaysCanFetchMore = ref(true);
  const {
    data: relaysData,
    loading: relaysLoading,
    fetchMoreSimplePaginationInput: relaysFetchMoreSimplePaginationInput,
    fetchMoreLoading: relaysFetchMoreLoading,
  } = useQuerySingleNode<
    PoolRelays_poolRelays_PoolRelaysResults,
    PoolRelays,
    PoolRelaysVariables
  >(
    poolRelaysGql,
    "PoolRelaysResults",
    () => ({
      input: {
        poolHash,
        pagination: {
          offset: 0,
          pageSize: 2,
        },
      },
    }),
    () => ({ enabled: !!poolHash })
  );

  const relaysFetchMore = async () => {
    await relaysFetchMoreSimplePaginationInput("poolRelays");
  };
  // END RELAYS

  /**
   * HISTORIES
   */
  const historiesCanFetchMore = ref(true);
  const {
    data: historiesData,
    loading: historiesLoading,
    fetchMoreSimplePaginationInput: historiesFetchMoreSimplePaginationInput,
    fetchMoreLoading: historiesFetchMoreLoading,
  } = useQuerySingleNode<
    PoolHistories_poolHistories_PoolHistoriesResults,
    PoolHistories,
    PoolHistoriesVariables
  >(
    poolHistoriesGql,
    "PoolHistoriesResults",
    () => ({
      input: {
        poolHash,
        pagination: {
          offset: 0,
          pageSize: 2,
        },
      },
    }),
    () => ({ enabled: !!poolHash })
  );

  const historiesFetchMore = async () => {
    await historiesFetchMoreSimplePaginationInput("poolHistories");
  };
  // END HISTORIES

  return {
    blocksData,
    blocksDataList: computed(() => blocksData.value?.results),
    blocksLoading,
    blocksFetchMore,
    blocksFetchMoreLoading,
    blocksCanFetchMore,

    rewardsData,
    rewardsDataList: computed(() =>
      rewardsData.value?.results?.map(parsePoolReward)
    ),
    rewardsLoading,
    rewardsFetchMore,
    rewardsFetchMoreLoading,
    rewardsCanFetchMore,

    delegatorsData,
    delegatorsDataList: computed(() =>
      delegatorsData.value?.results?.map(parsePoolDelegation)
    ),
    delegatorsLoading,
    delegatorsFetchMore,
    delegatorsFetchMoreLoading,
    delegatorsCanFetchMore,

    relaysData,
    relaysDataList: computed(() =>
      relaysData.value?.results?.map(parsePoolRelays)
    ),
    relaysLoading,
    relaysFetchMore,
    relaysFetchMoreLoading,
    relaysCanFetchMore,

    historiesData,
    historiesDataList: computed(() =>
      historiesData.value?.results?.map(parsePoolHistories)
    ),
    historiesLoading,
    historiesFetchMore,
    historiesFetchMoreLoading,
    historiesCanFetchMore,
  };
};
