
import { useAccount } from "@/shared/composables/useAccount";
import { usePoolVote } from "@/shared/composables/usePoolVote";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    poolHash: {
      type: String,
      required: true,
    },
    voteId: {
      type: Number,
      default: 0, // vote ID 0 is invalid and will be treated as false in checking
    },
    voteCount: {
      type: Number,
      default: 0,
    },
    isVoted: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const { isAuthenticated, isDelegated } = useAccount();
    const localIsVoted = ref(props.isVoted);
    const localVoteId = ref<number>(props.voteId); // props value for voteId is not expected to change dynamically

    const {
      createPoolVote,
      createPoolVoteLoading,
      unvotePool,
      unvotePoolLoading,
    } = usePoolVote();

    const handleSubmit = async () => {
      try {
        if (localVoteId.value || localIsVoted.value) {
          await unvotePool({ voteId: localVoteId.value }, props.poolHash);
          localVoteId.value = 0;
          localIsVoted.value = false;
        } else {
          const response = await createPoolVote({ poolHash: props.poolHash });
          if (response?.id) {
            localVoteId.value = Number(response?.id);
            localIsVoted.value = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const disabledTitleText = computed(() => {
      if (!isAuthenticated.value) {
        return t("Please login to Vote!");
      }
      if (!isDelegated.value) {
        return t("Account is not Delegated!");
      }

      return "";
    });

    return {
      t,
      isAuthenticated,
      isDelegated,
      disabledTitleText,
      handleSubmit,
      localVoteId,
      localIsVoted,
      createPoolVote,
      createPoolVoteLoading,
      unvotePool,
      unvotePoolLoading,
    };
  },
});
