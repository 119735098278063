export const openInNewTab = (url?: string) => {
  if (!window || !url) return;

  window.open(url, "_blank");
};

/**
 * Timeout utility useful for async/await
 * @param delayMs
 * @returns
 */
export const sleep = (delayMs = 100) =>
  new Promise((resolve) => setTimeout(resolve, delayMs));
