import gql from "graphql-tag";

export const poolDelegatorsGql = gql`
  query PoolDelegators($input: PoolDelegatorsInput!) {
    poolDelegators(input: $input) {
      ... on PoolDelegatorsResults {
        results {
          fromStakingKey
          amount
          timestamp
        }
        pageInfo {
          nextOffset
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
