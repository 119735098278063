import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-vote" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_button, _mergeProps({
      class: [
        'button-vote__btn',
        (_ctx.localIsVoted || !!_ctx.localVoteId) && 'button-vote__btn-voted',
        (!_ctx.isAuthenticated || !_ctx.isDelegated) && 'not-authenticated',
      ]
    }, _ctx.$attrs, {
      disabled: !_ctx.isAuthenticated || !_ctx.isDelegated || _ctx.$attrs.disabled,
      loading: _ctx.createPoolVoteLoading || _ctx.unvotePoolLoading,
      title: _ctx.disabledTitleText,
      onClick: _withModifiers(_ctx.handleSubmit, ["stop"])
    }), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.localIsVoted || !!_ctx.localVoteId ? _ctx.t("Voted") : _ctx.t("Vote")), 1)
      ]),
      _: 1
    }, 16, ["class", "disabled", "loading", "title", "onClick"]),
    (_ctx.voteCount)
      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
          key: 0,
          class: "button-vote__score"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("{score} votes", { score: _ctx.voteCount })), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}