import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pool-table-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_GraphAxes = _resolveComponent("GraphAxes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_paragraph, { class: "pool-table-summary__description" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.poolData?.poolDetail?.shortDescription), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "mb-4 pool-table-summary__details-row" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryList, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            key: item.key,
            md: Math.floor(24 / _ctx.summaryList.length),
            xs: 24,
            class: "pool-table-summary__details-row-item"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_typography_text, {
                class: "small title",
                type: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_a_typography_paragraph, { class: "value" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.value), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["md"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_GraphAxes, {
      data: _ctx.poolGraph,
      loading: _ctx.poolGraphLoading,
      class: "md:mb-12 mb-8"
    }, null, 8, ["data", "loading"])
  ]))
}