
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CustomTable from "@/shared/components/CustomTable.vue";
import { formatLocaleDateTimeShort } from "@/shared/utils/date";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { usePoolTabDetails } from "@/shared/composables/usePoolTabDetails";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";
import { useBreakpoints } from "@/shared/utils/breakpoints";

export default defineComponent({
  components: { CustomTable },
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const { isMobileDown } = useBreakpoints();

    const {
      delegatorsDataList,
      delegatorsLoading,
      delegatorsFetchMore,
      delegatorsFetchMoreLoading,
    } = usePoolTabDetails(props.poolHash);

    const columns = [
      {
        title: t("Time"),
        dataIndex: "timestamp",
        key: "timestamp",
        slots: { customRender: "timestamp" },
      },
      {
        title: t("Address"),
        dataIndex: "address",
        key: "address",
        slots: { customRender: "address" },
      },
      ...(isMobileDown.value
        ? []
        : [
            {
              title: t("Amount"),
              dataIndex: "amount",
              key: "amount",
              slots: { customRender: "amount" },
            },
          ]),
    ];

    const handleRedirectToDeligation = async (delegation_address) => {
      await router.push({
        name: routeNames.delegation,
        params: {
          delegation_address,
        },
      });
    };

    return {
      t,
      delegatorsDataList,
      delegatorsLoading,
      delegatorsFetchMore,
      delegatorsFetchMoreLoading,
      handleRedirectToDeligation,
      columns,
    };
  },
  methods: { formatLocaleDateTimeShort, adaCurrencyFormatter },
});
