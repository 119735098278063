import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsList = _resolveComponent("NewsList")!
  const _component_LayoutMainSub = _resolveComponent("LayoutMainSub")!

  return (_openBlock(), _createBlock(_component_LayoutMainSub, null, {
    default: _withCtx(() => [
      _createVNode(_component_NewsList, {
        poolHash: _ctx.poolHash,
        readonly: ""
      }, null, 8, ["poolHash"])
    ]),
    _: 1
  }))
}