import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_link = _resolveComponent("a-typography-link")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_CustomCard = _resolveComponent("CustomCard")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_LayoutMainSub = _resolveComponent("LayoutMainSub")!

  return (_openBlock(), _createBlock(_component_LayoutMainSub, {
    class: "pool-tab-about mt-10 mb-24",
    title: _ctx.t('Pool owner')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_text, { class: "mr-4 block md:inline" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.mockPoolAbout.stake), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_link, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.mockPoolAbout.stakeKeyHash), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_a_typography_title, {
        class: "mt-16",
        level: 5
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Relays")), 1)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relaysDataList, (relay, index) => {
        return (_openBlock(), _createBlock(_component_CustomCard, {
          key: relay.ip6Address + index,
          class: "pool-tab-about__card-relay mb-4"
        }, {
          default: _withCtx(() => [
            (relay.dnsName || relay?.status)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_a_typography_text, { class: "font-bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(relay.dnsName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_typography_text, {
              class: "block small mb-6",
              type: "secondary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(relay?.location), 1)
              ]),
              _: 2
            }, 1024),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.replayColumnsCardMode, (col) => {
              return (_openBlock(), _createElementBlock("div", {
                key: col.key,
                class: "pool-tab-about__card-relay-details"
              }, [
                _createVNode(_component_a_typography_text, { class: "label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(col.title), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_typography_text, { class: "text" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(relay?.[col.key]), 1)
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(_component_CustomTable, {
        class: "pool-tab-about__card-table",
        columns: _ctx.replayColumns,
        "data-source": _ctx.relaysDataList,
        loading: _ctx.relaysLoading,
        "show-pagination": false,
        "load-more-button": "",
        "load-more-loading": _ctx.relaysFetchMoreLoading,
        "page-size": _ctx.relaysDataList?.length,
        onLoadMore: _ctx.relaysFetchMore
      }, null, 8, ["columns", "data-source", "loading", "load-more-loading", "page-size", "onLoadMore"]),
      _createVNode(_component_a_typography_title, {
        class: "mt-16",
        level: 5
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("History")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_CustomTable, {
        columns: _ctx.historyColumns,
        "data-source": _ctx.historiesDataList,
        loading: _ctx.historiesLoading,
        "show-pagination": false,
        "load-more-button": "",
        "load-more-loading": _ctx.historiesFetchMoreLoading,
        "page-size": _ctx.historiesDataList?.length,
        onLoadMore: _ctx.historiesFetchMore
      }, {
        updateTimestamp: _withCtx(({ text }) => [
          _createTextVNode(_toDisplayString(_ctx.formatLocaleDateTimeShort(text)), 1)
        ]),
        pledge: _withCtx(({ text }) => [
          _createTextVNode(_toDisplayString(_ctx.adaCurrencyFormatter(text)), 1)
        ]),
        fixedCost: _withCtx(({ text }) => [
          _createTextVNode(_toDisplayString(_ctx.adaCurrencyFormatter(text)), 1)
        ]),
        margin: _withCtx(({ text }) => [
          _createTextVNode(_toDisplayString(text) + "% ", 1)
        ]),
        _: 1
      }, 8, ["columns", "data-source", "loading", "load-more-loading", "page-size", "onLoadMore"]),
      _createVNode(_component_a_typography_title, {
        class: "mt-16 mb-4",
        level: 5
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Operated by")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, { class: "whitespace-pre-wrap" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.mockPoolAbout.operatedBy), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}