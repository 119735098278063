import { useQuerySingleNode } from "@/api/graphqlClient/useQuerySingleNode";
import { poolGraphDetailsGql } from "@/api/pool/poolGraphDetails";
import {
  PoolGraphDetails,
  PoolGraphDetailsVariables,
  PoolGraphDetails_poolGraphDetails_PoolGraphDetailsResults,
} from "@/api/pool/__generated__/PoolGraphDetails";
import { computed } from "vue";

export const usePoolGraphDetails = (poolHash: string) => {
  const { data, loading: poolGraphLoading } = useQuerySingleNode<
    PoolGraphDetails_poolGraphDetails_PoolGraphDetailsResults,
    PoolGraphDetails,
    PoolGraphDetailsVariables
  >(
    poolGraphDetailsGql,
    "PoolGraphDetailsResults",
    () => ({ input: { poolHash } }),
    () => ({ enabled: !!poolHash })
  );

  return {
    poolGraph: computed(
      () =>
        data.value?.results?.map((poolGraphItem) => ({
          ...poolGraphItem,
          epochNumber: poolGraphItem?.epoch?.number,
          voteStrike: Number(poolGraphItem?.voteStrike),
        })) ?? []
    ),
    poolGraphLoading,
  };
};
