import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createBlock(_component_CustomTable, {
    columns: _ctx.columns,
    "data-source": _ctx.blocksDataList,
    loading: _ctx.blocksLoading,
    "show-pagination": false,
    "load-more-button": "",
    "load-more-loading": _ctx.blocksFetchMoreLoading,
    "page-size": _ctx.blocksDataList?.length,
    onLoadMore: _ctx.blocksFetchMore
  }, {
    hash: _withCtx(({ text }) => [
      _createVNode(_component_a_button, {
        type: "link",
        size: "small",
        onClick: ($event: any) => (_ctx.handleRedirectToBlock(text))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(text), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    timestamp: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.formatLocaleDateTimeShort(text)), 1)
    ]),
    txPercentage: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.decimalToPercentage(text)) + "% ", 1)
    ]),
    _: 1
  }, 8, ["columns", "data-source", "loading", "load-more-loading", "page-size", "onLoadMore"]))
}