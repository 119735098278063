import gql from "graphql-tag";

export const poolHistoriesGql = gql`
  query PoolHistories($input: PoolHistoriesInput!) {
    poolHistories(input: $input) {
      ... on PoolHistoriesResults {
        results {
          rewardAddress
          pledge
          fixedCost
          margin
          updateTimestamp
        }
        pageInfo {
          nextOffset
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
