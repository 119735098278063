import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_LayoutMainSub = _resolveComponent("LayoutMainSub")!

  return (_openBlock(), _createBlock(_component_LayoutMainSub, {
    title: _ctx.t('About {pool}', { pool: _ctx.poolData?.poolDetail?.name })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_typography_paragraph, { class: "text-justify" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.poolData?.poolDetail?.longDescription), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}