import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pool-handles__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, { class: "pool-handles" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handles, (handle) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: handle.name
        }, [
          (handle.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                (handle.icon)
                  ? (_openBlock(), _createBlock(_component_PlatformIcon, {
                      key: 0,
                      name: handle.icon,
                      class: "pool-handles__item-icon",
                      onClick: _withModifiers(($event: any) => (_ctx.openInNewTab(handle.value)), ["stop"])
                    }, null, 8, ["name", "onClick"]))
                  : (_openBlock(), _createBlock(_component_a_typography_text, {
                      key: 1,
                      type: "secondary",
                      class: "small",
                      onClick: _withModifiers(($event: any) => (_ctx.openInNewTab(handle.value)), ["stop"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(handle.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}