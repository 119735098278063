import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_PoolHeaderSummary = _resolveComponent("PoolHeaderSummary")!
  const _component_PoolTableSummary = _resolveComponent("PoolTableSummary")!
  const _component_PoolTabSummary = _resolveComponent("PoolTabSummary")!
  const _component_LayoutMain = _resolveComponent("LayoutMain")!

  return (_openBlock(), _createBlock(_component_LayoutMain, {
    class: "page-pool",
    "back-text": _ctx.t('Back to pools'),
    onOnBack: _ctx.handleBack
  }, {
    default: _withCtx(() => [
      (_ctx.poolDataLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_PoolHeaderSummary, {
              class: "page-pool__section",
              "pool-data": _ctx.poolData,
              "pool-hash": _ctx.poolHash
            }, null, 8, ["pool-data", "pool-hash"]),
            _createVNode(_component_PoolTableSummary, {
              class: "page-pool__section",
              "pool-data": _ctx.poolData
            }, null, 8, ["pool-data"]),
            _createVNode(_component_PoolTabSummary, {
              class: "page-pool__section",
              "pool-data": _ctx.poolData,
              "pool-hash": _ctx.poolHash
            }, null, 8, ["pool-data", "pool-hash"])
          ], 64))
    ]),
    _: 1
  }, 8, ["back-text", "onOnBack"]))
}