
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import productFallbackIcon from "@/assets/icons/product_fallback.svg";
import ButtonVote from "@/shared/components/Buttons/ButtonVote.vue";
import { computed } from "@vue/reactivity";
import { parsePoolHandlers } from "@/shared/utils/pool";
import PoolHandles from "@/shared/components/Pool/PoolHandles.vue";
import { Pools_pools_PoolItemResults_results } from "@/api/pool/__generated__/Pools";

export default defineComponent({
  components: { ButtonVote, PoolHandles },
  props: {
    poolHash: {
      type: String,
      required: true,
    },
    poolData: {
      type: Object as PropType<Pools_pools_PoolItemResults_results>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    return {
      t,
      productFallbackIcon,
      handles: computed(() => parsePoolHandlers(props?.poolData?.poolDetail)),
    };
  },
  methods: {},
});
