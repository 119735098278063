import gql from "graphql-tag";

export const poolBlocksGql = gql`
  query PoolBlocks($input: PoolBlocksInput!) {
    poolBlocks(input: $input) {
      ... on PoolBlocksResults {
        results {
          epoch {
            id
            created
            number
          }
          slotNumber
          blockNumber
          blockHash
          txPercentage
          versionNumber
          certIndex
          timestamp
        }
        pageInfo {
          nextOffset
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
