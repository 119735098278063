
import { defineComponent, PropType, ref, h } from "vue";
import { useI18n } from "vue-i18n";
import PoolTabIntroduction from "@/web/views/Pool/PoolTabs/PoolTabIntroduction.vue";
import PoolTabMissionNews from "@/web/views/Pool/PoolTabs/PoolTabMissionNews.vue";
import PoolTabBlocks from "@/web/views/Pool/PoolTabs/PoolTabBlocks.vue";
import PoolTabRewards from "@/web/views/Pool/PoolTabs/PoolTabRewards.vue";
import PoolTabDelegation from "@/web/views/Pool/PoolTabs/PoolTabDelegation.vue";
import PoolTabAbout from "@/web/views/Pool/PoolTabs/PoolTabAbout.vue";
import CustomTabs from "@/shared/components/custom-tabs.vue";
import CreditCardOutlined from "@ant-design/icons-vue/CreditCardOutlined";
import BellOutlined from "@ant-design/icons-vue/BellOutlined";
import AppstoreOutlined from "@ant-design/icons-vue/AppstoreOutlined";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import RiseOutlined from "@ant-design/icons-vue/RiseOutlined";
import InfoCircleOutlined from "@ant-design/icons-vue/InfoCircleOutlined";
import { Pools_pools_PoolItemResults_results } from "@/api/pool/__generated__/Pools";

export default defineComponent({
  props: {
    poolHash: {
      type: String,
      required: true,
    },
    poolData: {
      type: Object as PropType<Pools_pools_PoolItemResults_results>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const activeTabKey = ref();
    const tabList = [
      {
        name: "introduction",
        title: t("Introduction"),
        component: PoolTabIntroduction,
        icon: CreditCardOutlined,
      },
      {
        name: "performance",
        title: t("Mission news"),
        component: PoolTabMissionNews,
        icon: BellOutlined,
      },
      {
        name: "blocks",
        title: t("Blocks"),
        component: PoolTabBlocks,
        icon: AppstoreOutlined,
      },
      {
        name: "rewards",
        title: t("Rewards"),
        component: PoolTabRewards,
        /**
         * h is is used to create Vnodes
         * See: https://vuejs.org/guide/extras/render-function.html#creating-vnodes
         */
        icon: h(AppIcon, {
          name: "reward",
          style: { height: "1.1em" },
          class: "mr-1",
        }),
      },
      {
        name: "delegation",
        title: t("Delegation"),
        component: PoolTabDelegation,
        icon: RiseOutlined,
      },
      {
        name: "about",
        title: t("About"),
        component: PoolTabAbout,
        icon: InfoCircleOutlined,
      },
    ];
    return { t, activeTabKey, tabList };
  },
  components: { CustomTabs },
});
