import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pool-header-summary flex" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "pool-header-summary__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_PoolHandles = _resolveComponent("PoolHandles")!
  const _component_ButtonVote = _resolveComponent("ButtonVote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.poolData?.poolDetail?.logo)
          ? (_openBlock(), _createBlock(_component_a_image, {
              key: 0,
              class: "pool-header-summary__title-image",
              src: _ctx.poolData?.poolDetail?.logo,
              width: 40,
              fallback: _ctx.productFallbackIcon
            }, null, 8, ["src", "fallback"]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_typography_title, {
          level: 1,
          class: "pool-header-summary__title-text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.poolData?.poolDetail?.ticker) + " · " + _toDisplayString(_ctx.poolData?.poolDetail?.name), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_PoolHandles, { handles: _ctx.handles }, null, 8, ["handles"])
    ]),
    _createVNode(_component_ButtonVote, {
      class: "pool-header-summary__button-vote",
      "vote-count": _ctx.poolData?.poolVoteDetail?.voteCount,
      "is-voted": _ctx.poolData?.isVoted,
      "pool-hash": _ctx.poolHash,
      "vote-id": _ctx.poolData.voteId
    }, null, 8, ["vote-count", "is-voted", "pool-hash", "vote-id"])
  ]))
}