import gql from "graphql-tag";

export const createPoolVoteGql = gql`
  mutation CreatePoolVote($input: CreatePoolVoteInput!) {
    createPoolVote(input: $input) {
      ... on PoolVote {
        id
        created
        status
        delegation {
          id
          fromStakingKey {
            id
            created
            modified
            user {
              id
              username
              name
              avatarUrl
            }
            stakingKey
            delegations {
              id
              fromStakingKey {
                id
                created
                modified
                stakingKey
              }
              startTx {
                id
                amount
                txid
                stakingKey
                type
              }
              endTx {
                id
                amount
                txid
                stakingKey
                type
              }
            }
          }
          startTx {
            id
            amount
            txid
            stakingKey
            type
            epoch {
              id
              created
              number
            }
          }
          endTx {
            id
            amount
            txid
            stakingKey
            type
            epoch {
              id
              created
              number
            }
          }
        }
        epoch {
          id
          created
          number
        }
        pool {
          id
          address
          status
        }
        secretKey {
          id
          created
          modified
          user {
            id
            username
            name
            avatarUrl
          }
          secretKey
          status
          poolVotes {
            id
            created
            status
            delegation {
              id
              fromStakingKey {
                id
                created
                modified
                stakingKey
              }
              startTx {
                id
                amount
                txid
                stakingKey
                type
              }
              endTx {
                id
                amount
                txid
                stakingKey
                type
              }
            }
            epoch {
              id
              created
              number
            }
            pool {
              id
              address
              status
            }
            secretKey {
              id
              created
              modified
              user {
                id
                username
                name
                avatarUrl
              }
              secretKey
              status
              poolVotes {
                id
                created
                status
              }
            }
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
